<template>
    <div v-if="user_info" class="level">
        <div class="progress">
            <v-progress-linear :value="(Number(user_info.level_now) * 100) / Number(user_info.level_next)" :height="4"></v-progress-linear>
        </div>
        <div class="lvl">
            <p class="l">
                <img src="/dist/images/star.svg" alt="">
                <span>Level {{ user_info.level}}</span>
            </p>
            <p class="t">{{user_info.level_now}}/{{user_info.level_next}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LevelProgress',
        data: function() {
            return {

            }
        },
        props: {

        },
        computed: {
            user_info() {
                return this.$store.state.auth.user_info
            },
        },
        created () {

        },
        methods: {


        },

    }
</script>
<style scoped>


</style>
