let tonConnectInstance = null;

export function getTonConnectInstance() {
    return tonConnectInstance;
}

export function setTonConnectInstance(instance) {
    if (!tonConnectInstance) {
        tonConnectInstance = instance;
    }
}