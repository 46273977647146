<template>
    <div class="card-page ">
        <LevelProgress></LevelProgress>
        <ProfitMenu></ProfitMenu>


        <div class="tab-block">
            <v-tabs
                    v-model="tab"
            >
                <v-tab>
                    <p>21 DAYS</p>
                </v-tab>
                <v-tab>
                    <p>Unlimited</p>
                </v-tab>
                <v-tab class="my-cards">
                    <p>My Cards</p>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card flat>
                        <ItemCards
                            v-for="(item, key) in list_plans"
                            :key="key"
                            :item.sync="item"
                            :type="Number(tab)+1"
                            :dialogText.sync="dialogText"
                        >
                        </ItemCards>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <ItemCards
                            v-for="(item, key) in list_plans"
                            :key="key"
                            :item.sync="item"
                            :type="Number(tab)+1"
                            :dialogText.sync="dialogText"
                        >
                        </ItemCards>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat>
                        <ItemCards
                            v-for="(item, key) in list_plans"
                            :key="key"
                            :item.sync="item"
                            :type="Number(tab)+1"
                            :dialogText.sync="dialogText"
                        >
                        </ItemCards>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </div>

        <ModalDefault v-if="dialogText" v-bind:modal_cancel.sync="dialogText">
            <div class="window-buy">
                <div class="window-block">
                    <p class="name">{{dialogText.name}}</p>
                    <div class="img"></div>
                    <p class="desc">{{dialogText.text[0].text}}</p>
                    <div class="block">
                        <div class="profit">
                            <p>Прибыль в час:</p>
                            <span>+{{this.$primNum(dialogText.profit_day, 2)}} NOT / Day</span>
                        </div>
                        <div class="summ profit">
                            <p>Стоимость</p>
                            <span>
                        <img src="/dist/images/ton-symbol.svg" alt="">
                        {{this.$primNum(dialogText.price, 2)}}
                    </span>
                        </div>
                    </div>
                    <div class="btn-block">
                        <v-btn
                            :loading="submitDisabled"
                            class="btn-blue"
                            @click="buyPlan"
                        >
                            Buy
                        </v-btn>
                    </div>
                </div>
            </div>
        </ModalDefault>

        <ModalDefault v-if="dialogMy" v-bind:modal_cancel.sync="dialogMy">
            <div class="window-buy">
                <div class="window-block">
<!--                    <p class="name">{{dialogText.name}}</p>-->
                    <div class="img"></div>
<!--                    <p class="desc">{{dialogText.text[0].text}}</p>-->
                    <div class="block">
                        <div class="profit">
                            <p>Прибыль в час:</p>
<!--                            <span>+{{this.$primNum(dialogText.profit_day, 2)}} NOT / Day</span>-->
                        </div>
                        <div class="summ profit">
                            <p>Стоимость</p>
                            <span>
                                <img src="/dist/images/ton-symbol.svg" alt="">
<!--                                {{this.$primNum(dialogText.price, 2)}}-->
                            </span>
                        </div>
                    </div>
                    <p class="total">Всего будет начислено
                        <span><img src="/dist/images/ton-symbol.svg" alt=""> 0.15</span>
                    </p>
                    <div class="btn-block">
                        <v-btn
                                :loading="submitDisabled"
                                class="btn-blue"
                                @click="buyPlan"
                        >
                            Buy
                        </v-btn>
                    </div>
                </div>
            </div>
        </ModalDefault>

    </div>
</template>

<script>

import ModalDefault from "../modules/ModalDefault";
import LevelProgress from "../modules/LevelProgress";
import ProfitMenu from "../modules/ProfitMenu";
import ItemCards from "./Cards/ItemCards";
export default {
  name: 'cards',
    data: function() {
      return {
          dialogMy: false,
          dialogText: false,
          tab: null,
          list_plans: null,
          submitDisabled: false,
      }
  },
  watch: {
    tab(val) {
        this.list_plans = null;
        let type = Number(val) + 1;
        this.$http.get('ajax/plans/' + type)
            .then(response => {
                return response.json()
            })
            .then(response => {
                response = response.data;
                this.list_plans = response.plans;
            }).catch(err => {
            this.httpHandler(err);
        });
    }
  },

    created() {

    },
    destroyed() {

    },
    methods: {
        buyPlan() {
            this.submitDisabled = true;
            let request = {
                plan_id: this.dialogText.id,
            };
            this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/buy_plan', request)
                .then(response=> {
                    return response.json()
                })
                .then((response) => {
                    response = response.data;
                    this.$store.dispatch('asyncUserInfo', response.infoUser);
                    this.list_plans = response.plans;
                    this.submitDisabled = false;
                    this.dialogText = false;
                }).catch((err) => {
                this.httpHandler(err);
                this.submitDisabled = false;
            });

        }
    },
  computed: {
      languages() {
          return this.$store.state.language.list;
      },

  },
  mounted() {

  },
  components: {
      ItemCards,
      ModalDefault,
      ProfitMenu,
      LevelProgress

  }
}
</script>
