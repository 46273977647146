<template>
    <div class="partners">
        <p class="name-page">Пригласите друзей</p>
        <p class="desc-page">Вы и ваш друг получите бонусы</p>
        <div class="bonus">
            <p class="bonus-name">Ежедневный бонус от ваших друзей</p>
            <div class="text">
                <p><img src="/dist/images/Icon-mini.svg" alt=""> + 10% <span>от добычи NOT</span></p>
                <p><img src="/dist/images/energy.svg" alt=""> +10%  <span>энергии от энергии</span></p>
            </div>
        </div>
        <div class="btn-block">
            <v-btn class="btn-blue" @click="inviteFriend">
                Подарить другу 10 NOT
                <img src="/dist/images/tg.svg" alt="">
            </v-btn>
            <v-btn class="btn-blue btn-link" @click="copyInviteLink">
                <img src="/dist/images/link.svg" alt="">
            </v-btn>
        </div>
        <p class="text-frend">Приглашенный вами друг получит +10 NOT для покупки карточек</p>
        <div class="list" @scroll="scrollFriends" ref="friends_list">
            <p class="list-name">Ваши Друзья</p>
            <div v-if="list_friends && list_friends.length" class="list-scroll">
                <ItemFriends
                    v-for="(item, key) in list_friends"
                    :key="key"
                    :item.sync="item"
                ></ItemFriends>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import ItemFriends from "./Friends/ItemFriends";

export default {
  name: 'friends',
    data: function() {
      return {
          list_friends: null,
          next_page_url: null,
          loadingPage: false,
      }
  },
    computed: {
        user_info() {
            return this.$store.state.auth.user_info
        },

    },
  watch: {

  },

    created() {

    },
    destroyed() {

    },
    methods: {
        scrollFriends: function () {
            let friends_list = this.$refs.friends_list;
            const progress = friends_list.scrollTop / (friends_list.scrollHeight - friends_list.clientHeight);
            if (!this.loadingPage && this.next_page_url) {
                let bottomOfWindow = 0.7 <= progress;
                if (bottomOfWindow) {
                    this.loadingPage = true;
                    this.$http.get(this.next_page_url)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        response = response.data;
                        let list_friends = this.$cleanObject(this.list_friends);
                        list_friends = list_friends.concat(response.data.users.data);
                        this.list_friends = list_friends;
                        this.next_page_url = response.next_page_url.data.users.next_page_url;
                        this.loadingPage = false;
                    })
                    .catch((err) => {
                        this.httpHandler(err);
                    });
                }
            }
        },
        inviteFriend() {
            // URL deeplink для открытия Telegram с предзаполненным сообщением
            const telegramUrl = `https://t.me/share/url?url=${this.user_info.user.ref}&text=Привет!%20Я%20хочу%20пригласить%20тебя%20в%20приложение.`;
            window.open(telegramUrl, '_blank');
        },
        copyInviteLink() {
            this.$copyMessage(this.user_info.user.ref);
            Vue.notify({
                group: 'message',
                title: "Invitation url copied to clipboard.",
                type: 'success'
            });
        }
    },

    mounted() {
        this.$http.get('ajax/friends')
            .then(response => {
                return response.json()
            })
            .then((response) => {
                response = response.data;
                this.list_friends = response.users.data;
                this.next_page_url = response.users.next_page_url;

            }).catch((err) => {
            this.httpHandler(err);
        });
    },
  components: {
      ItemFriends
  }
}
</script>
