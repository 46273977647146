<template>
  <div>
    <div class="content-home">
      <div class="page-wrapper">
        <HomeMenu></HomeMenu>
        <v-main>
          <router-view/>
        </v-main>
        <HomeFooter></HomeFooter>
      </div>
    </div>
  </div>
</template>

<script>
import HomeMenu from "./HomeMenu";
import HomeFooter from "./HomeFooter";

export default {
  name: 'Home',
  data: function() {
    return {
      interval_generate_sec: null,
    }
  },
  computed: {
    user_info() {
      return this.$store.state.auth.user_info
    },
  },

  watch: {
      // user_info() {
      //   this.startTimers();
      // }
  },
  methods:{
    startTimers() {
      if (this.user_info) {
        clearInterval(this.interval_generate_sec);
        this.interval_generate_sec = setInterval(() => {
          let new_user_info = this.$cleanObject(this.user_info);
          new_user_info.available += Number(this.user_info.generate_sec);
          this.$store.dispatch('asyncUserInfo', new_user_info);
        }, 1000);
      }
    }
  },
  created() {
    // this.startTimers();
  },
  mounted() {

  },
  destroyed () {
    clearInterval(this.interval_generate_sec);
  },

  components: {
    HomeFooter,
    HomeMenu
  }
}


</script>

<style scoped>

</style>