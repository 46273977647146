export default {
    state: {
        isConnectedWallet: false,
        walletData: null
    },
    getters: {
        isConnectedWallet(state) {
            return state.isConnectedWallet;
        },
        walletData(state) {
            return state.walletData;
        }
    },
    actions: {
        asyncIsConnectedWallet({ commit }, payload) {
            commit('updateIsConnectedWallet', payload);
        },
        asyncWalletData({ commit }, payload) {
            commit('updateWalletData', payload);
        }
    },
    mutations: {
        updateIsConnectedWallet(state, data) {
            state.isConnectedWallet = data;
        },
        updateWalletData(state, data) {
            state.walletData = data;
        }
    }
}