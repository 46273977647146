<template>
    <keep-alive>
        <div class="deposit-page">
            <div class="fin-block">
                <div class="dropdown">
                    <button @click="toggleDropdown">
                        Добавить NOT
                        <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.07745 0.910723C1.40289 0.585286 1.93053 0.585286 2.25596 0.910723L6.66671 5.32147L11.0775 0.910723C11.4029 0.585286 11.9305 0.585286 12.256 0.910723C12.5814 1.23616 12.5814 1.7638 12.256 2.08923L7.25596 7.08924C6.93053 7.41467 6.40289 7.41467 6.07745 7.08924L1.07745 2.08923C0.752015 1.7638 0.752015 1.23616 1.07745 0.910723Z" fill="#FCFCFF"/>
                        </svg>
                    </button>
                    <div v-if="isDropdownOpen" class="dropdown-content">
                        <router-link :to="{ name: 'deposit' }" @click="selectItem('Опция 1')">Добавить NOT</router-link>
                        <router-link :to="{ name: 'withdrawal' }" @click="selectItem('Опция 2')">Отправить NOT на кошелек</router-link>
                    </div>
                </div>
                <div class="block-input">
                    <img src="/dist/images/icon-ton.svg" alt="">

                    <InputLabelTextDefault
                            placeholder="10"
                            v-model="amount"
                            type="number"
                    />
                    <p class="ton">NOT</p>
                </div>

                <p>{{this.$APP_URL}}/tonconnect-manifest.json</p>

                <p>{{this.amount * (10 ** 9)}} нанотонов</p>
                <br>
                <br>
                <br>
                <!-- Кнопка для подключения кошелька -->
                <button v-if="!isConnectedWallet" @click="openTonConnectUI">Подключить кошелек</button>

                <!-- Если кошелек подключен, показываем информацию и кнопку пополнения -->
                <div v-else>
                    <p>Кошелек подключен: {{ walletData?.account.address }}</p>
                    <button @click="sendTransaction">Пополнить TON</button>
                    <button @click="disconnectWallet">Отключить кошелек</button>

                    <br>
                    <br>
                    <br>
                    <button @click="sendJettonTransaction">Пополнить NOT!!!!!!</button><br>
                    <br>
                    <br>
                    <button @click="sendJettonTransactionNew">Пополнить NOT NEW!!!!!!</button>
                </div>
                <br>
                <br>
                <br>

                <pre v-if="walletData">{{walletData}}</pre>

                <!--            <div class="btn-block">-->
                <!--                <a class="btn-blue">Пополнить баланс</a>-->
                <!--            </div>-->
                <div>

                </div>
                <!--            <div class="text">-->
                <!--                <img src="/dist/images/dep-2.svg" alt="">-->
                <!--                <p>Пополнив баланс - вы можете быстрее всех улучшить свой печатныей станок</p>-->
                <!--            </div>-->
                <!--            <div class="text">-->
                <!--                <img src="/dist/images/dep-1.svg" alt="">-->
                <!--                <p>Минимальный депозит <span>1 NOT</span></p>-->
                <!--            </div>-->
                <!--            <div class="text">-->
                <!--                <img src="/dist/images/dep-3.svg" alt="">-->
                <!--                <p>Средства поступят автоматически в течении 10 минут</p>-->
                <!--            </div>-->
            </div>
        </div>
    </keep-alive>
</template>

<script>

import { TonConnect } from '@tonconnect/sdk';
import { TonConnectUI } from "@tonconnect/ui";
import TonWeb from 'tonweb';
import InputLabelTextDefault from "../modules/InputLabelTextDefault";
import Vue from "vue";
import { getTonConnectInstance, setTonConnectInstance } from '../../tonconnectService';

export default {
  name: 'deposit',
    data: function() {
      return {
          amount: null,
          isDropdownOpen: false,
          tonConnectUI: null,
          selectedItem: null,
          tonConnect: new TonConnect(),
          JETTON_CONTRACT_ADDRESS: 'EQAvlWFDxGF2lXm67y4yzC17wYKD9A0guwPkMs1gOsM__NOT',
          // walletData: {
          //     "device": {
          //         "platform": "iphone",
          //         "appName": "Tonkeeper",
          //         "appVersion": "4.10.1",
          //         "maxProtocolVersion": 2,
          //         "features": [
          //             "SendTransaction",
          //             {
          //                 "name": "SendTransaction",
          //                 "maxMessages": 4
          //             }
          //         ]
          //     },
          //     "provider": "http",
          //     "account": {
          //         "address": "0:770891561aec1e82ae72d7031fff236b1da36313b53c803acef28354a8abcd12",
          //         "chain": "-239",
          //         "walletStateInit": "te6cckECFgEAAwQAAgE0AgEAUQAAAAApqaMX3BawstD+QCwM/9i3dkRqtVnsUmx6PYvP5H+ocsn/oq1AART/APSkE/S88sgLAwIBIAkEBPjygwjXGCDTH9Mf0x8C+CO78mTtRNDTH9Mf0//0BNFRQ7ryoVFRuvKiBfkBVBBk+RDyo/gAJKTIyx9SQMsfUjDL/1IQ9ADJ7VT4DwHTByHAAJ9sUZMg10qW0wfUAvsA6DDgIcAB4wAhwALjAAHAA5Ew4w0DpMjLHxLLH8v/CAcGBQAK9ADJ7VQAbIEBCNcY+gDTPzBSJIEBCPRZ8qeCEGRzdHJwdIAYyMsFywJQBc8WUAP6AhPLassfEss/yXP7AABwgQEI1xj6ANM/yFQgR4EBCPRR8qeCEG5vdGVwdIAYyMsFywJQBs8WUAT6AhTLahLLH8s/yXP7AAIAbtIH+gDU1CL5AAXIygcVy//J0Hd0gBjIywXLAiLPFlAF+gIUy2sSzMzJc/sAyEAUgQEI9FHypwICAUgTCgIBIAwLAFm9JCtvaiaECAoGuQ+gIYRw1AgIR6STfSmRDOaQPp/5g3gSgBt4EBSJhxWfMYQCASAODQARuMl+1E0NcLH4AgFYEg8CASAREAAZrx32omhAEGuQ64WPwAAZrc52omhAIGuQ64X/wAA9sp37UTQgQFA1yH0BDACyMoHy//J0AGBAQj0Cm+hMYALm0AHQ0wMhcbCSXwTgItdJwSCSXwTgAtMfIYIQcGx1Z70ighBkc3RyvbCSXwXgA/pAMCD6RAHIygfL/8nQ7UTQgQFA1yH0BDBcgQEI9ApvoTGzkl8H4AXTP8glghBwbHVnupI4MOMNA4IQZHN0crqSXwbjDRUUAIpQBIEBCPRZMO1E0IEBQNcgyAHPFvQAye1UAXKwjiOCEGRzdHKDHrFwgBhQBcsFUAPPFiP6AhPLassfyz/JgED7AJJfA+IAeAH6APQEMPgnbyIwUAqhIb7y4FCCEHBsdWeDHrFwgBhQBMsFJs8WWPoCGfQAy2kXyx9SYMs/IMmAQPsABgByW/4=",
          //         "publicKey": "dc16b0b2d0fe402c0cffd8b776446ab559ec526c7a3d8bcfe47fa872c9ffa2ad"
          //     },
          //     "name": "Tonkeeper",
          //     "appName": "tonkeeper",
          //     "imageUrl": "https://tonkeeper.com/assets/tonconnect-icon.png",
          //     "aboutUrl": "https://tonkeeper.com",
          //     "tondns": "tonkeeper.ton",
          //     "platforms": [
          //         "ios",
          //         "android",
          //         "chrome",
          //         "firefox",
          //         "macos"
          //     ],
          //     "bridgeUrl": "https://bridge.tonapi.io/bridge",
          //     "universalLink": "https://app.tonkeeper.com/ton-connect",
          //     "deepLink": "tonkeeper-tc://",
          //     "jsBridgeKey": "tonkeeper",
          //     "injectet": false,
          //     "embedded": false,
          //     "isPreferred": true,
          //     "openMethod": "universal-link"
          // }
      }
  },
  watch: {

  },
    computed: {
        isConnectedWallet() {
            return this.$store.state.deposit.isConnectedWallet;
        },
        walletData() {
            return this.$store.state.deposit.walletData;
        }
    },

    mounted() {
        let tonConnectUI = getTonConnectInstance();
        this.tonConnectUI = tonConnectUI;
        if (!tonConnectUI) {
            tonConnectUI = new TonConnectUI({
                manifestUrl: process.env.NODE_ENV === 'production'
                    ? this.$APP_URL + '/tonconnect-manifest.json'
                    : 'http://localhost:8080/tonconnect-manifest.json',
            });

            setTonConnectInstance(tonConnectUI);
            this.tonConnectUI = tonConnectUI;
            // Подписываемся на изменения статуса подключения
            tonConnectUI.onStatusChange((wallet) => {
                if (wallet) {
                    console.log('Кошелек подключен:', wallet);
                    this.$store.dispatch('asyncWalletData', wallet);
                    this.$store.dispatch('asyncIsConnectedWallet', true);
                } else {
                    console.log('Кошелек отключен');
                    this.$store.dispatch('asyncIsConnectedWallet', false);
                    this.$store.dispatch('asyncWalletData', null);
                }
            });
        }


        const connectedWallet = this.tonConnect.wallet;

        if (connectedWallet) {
            // Если кошелек уже подключен, сохраняем его данные
            console.log('Кошелек уже подключен:', connectedWallet);
            this.$store.dispatch('asyncWalletData', connectedWallet);
            this.$store.dispatch('asyncIsConnectedWallet', true);
        }
    },

    created() {

    },
    destroyed() {

    },

    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectItem(item) {
            this.selectedItem = item;
            this.isDropdownOpen = false;
        },


        // Открытие интерфейса выбора кошельков
        openTonConnectUI() {
            this.tonConnectUI.connectWallet();
        },

        // Метод для отправки транзакции TON
        async sendTransaction() {
            if (!this.walletData) return;

            try {
                const tx = {
                    validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                    messages: [
                        {
                            address: "UQASXh7YqsPyLFdSjGzDxLBYMGXdIrjvW9xWJPeCGgE4y0Lo",
                            amount: (this.amount * (10 ** 9)).toString(),
                        }
                    ]
                };

                // Отправка транзакции
                await this.tonConnectUI.sendTransaction(tx);

                console.log('Транзакция успешно выполнена!');
                Vue.notify({
                    group: 'message',
                    title: "Транзакция успешно выполнена!",
                    type: 'success'
                });
            } catch (error) {
                console.error('Ошибка при отправке транзакции:', error);
                Vue.notify({
                    group: 'message',
                    title: 'Ошибка при отправке транзакции',
                    text: error.message,
                    type: 'error'
                });
            }
        },

        // Метод для отправки транзакции NOT
        async sendJettonTransaction() {
            if (!this.walletData) return;

            try {
                const tonweb = new TonWeb(); // Инициализируем TonWeb
                const walletAddress = new TonWeb.utils.Address(this.walletData.account.address); // Преобразуем строку в объект Address
                const recipientAddress = new TonWeb.utils.Address("UQASXh7YqsPyLFdSjGzDxLBYMGXdIrjvW9xWJPeCGgE4y0Lo"); // Преобразуем строку в объект Address

                // Создаем контракт Jetton Minter
                const jettonMinter = new TonWeb.token.jetton.JettonMinter(tonweb.provider, {address: this.JETTON_CONTRACT_ADDRESS});

                // Получаем адрес Jetton Wallet для данного Jetton контракта
                const jettonWalletAddress = await jettonMinter.getJettonWalletAddress(walletAddress);

                // Количество Notcoin для отправки (не забудьте учесть десятичные знаки)
                // const amountToSend = this.amount * (10 ** 9); // Если Notcoin имеет 9 десятичных знаков
                const amountToSend = TonWeb.utils.toNano(this.amount.toString()); // Передаем количество Jetton в виде строки

                // Создаем объект Jetton Wallet для отправителя
                const jettonWallet = new TonWeb.token.jetton.JettonWallet(tonweb.provider, {
                    address: jettonWalletAddress
                });

                // Создаем тело транзакции
                // const transferBody = await tonweb.jetton.createTransferBody({
                //     tokenAmount: new TonWeb.utils.toNano(amountToSend.toString()), // Количество Jetton токенов для отправки
                //     toAddress: recipientAddress, // Преобразуем объект Address в строку
                //     responseAddress: walletAddress // Преобразуем объект Address в строку
                // });
                const transferBody = await jettonWallet.createTransferBody({
                    tokenAmount: amountToSend, // Количество Jetton токенов для отправки
                    toAddress: recipientAddress, // Преобразуем объект Address в строку
                    responseAddress: walletAddress // Преобразуем объект Address в строку
                });


                // Преобразуем объект Cell в массив байтов
                const transferBodyBytes = await transferBody.toBoc();

                // Формируем сообщение для отправки Jetton токенов
                const tx = {
                    validUntil: Math.floor(Date.now() / 1000) + 60, // 60 секунд
                    messages: [
                        {
                            address: jettonWalletAddress.toString(), // Преобразуем объект Address в строку
                            amount: "50000000", // Небольшая сумма в TON для оплаты комиссии (в нанотонах)
                            payload: TonWeb.utils.bytesToBase64(transferBodyBytes) // Преобразуем payload в строку
                        }
                    ]
                };

                // Отправка транзакции через TonConnect
                await this.tonConnectUI.sendTransaction(tx);

                // Уведомление об успешной транзакции
                Vue.notify({
                    group: 'message',
                    title: "Транзакция успешно выполнена!",
                    type: 'success'
                });
            } catch (error) {
                // Обработка ошибок
                console.error('Ошибка при отправке транзакции:', error);
                Vue.notify({
                    group: 'message',
                    title: 'Ошибка при отправке транзакции',
                    text: error.message,
                    type: 'error'
                });
            }
        },
        async sendJettonTransactionNew() {
            if (!this.walletData) return;

            try {
                const tonweb = new TonWeb(); // Инициализируем TonWeb
                const walletAddress = new TonWeb.utils.Address(this.walletData.account.address); // Преобразуем строку в объект Address
                const recipientAddress = new TonWeb.utils.Address("UQASXh7YqsPyLFdSjGzDxLBYMGXdIrjvW9xWJPeCGgE4y0Lo"); // Преобразуем строку в объект Address

                // Создаем контракт Jetton Minter
                const jettonMinter = new TonWeb.token.jetton.JettonMinter(tonweb.provider, {address: this.JETTON_CONTRACT_ADDRESS});

                // Получаем адрес Jetton Wallet для данного Jetton контракта
                const jettonWalletAddress = await jettonMinter.getJettonWalletAddress(walletAddress);

                // Количество Notcoin для отправки (не забудьте учесть десятичные знаки)
                // const amountToSend = this.amount * (10 ** 9); // Если Notcoin имеет 9 десятичных знаков
                const amountToSend = TonWeb.utils.toNano(this.amount.toString()); // Передаем количество Jetton в виде строки

                // Создаем объект Jetton Wallet для отправителя
                const jettonWallet = new TonWeb.token.jetton.JettonWallet(tonweb.provider, {
                    address: jettonWalletAddress
                });

                // Создаем тело транзакции
                // const transferBody = await tonweb.jetton.createTransferBody({
                //     tokenAmount: new TonWeb.utils.toNano(amountToSend.toString()), // Количество Jetton токенов для отправки
                //     toAddress: recipientAddress, // Преобразуем объект Address в строку
                //     responseAddress: walletAddress // Преобразуем объект Address в строку
                // });
                const transferBody = await jettonWallet.createTransferBody({
                    jettonAmount: amountToSend, // Количество Jetton токенов для отправки
                    toAddress: recipientAddress, // Преобразуем объект Address в строку
                    responseAddress: walletAddress // Преобразуем объект Address в строку
                });


                // Преобразуем объект Cell в массив байтов
                const transferBodyBytes = await transferBody.toBoc();

                // Формируем сообщение для отправки Jetton токенов
                const tx = {
                    validUntil: Math.floor(Date.now() / 1000) + 60, // 60 секунд
                    messages: [
                        {
                            address: jettonWalletAddress.toString(), // Преобразуем объект Address в строку
                            amount: "50000000", // Небольшая сумма в TON для оплаты комиссии (в нанотонах)
                            payload: TonWeb.utils.bytesToBase64(transferBodyBytes) // Преобразуем payload в строку
                        }
                    ]
                };

                // Отправка транзакции через TonConnect
                await this.tonConnectUI.sendTransaction(tx);

                // Уведомление об успешной транзакции
                Vue.notify({
                    group: 'message',
                    title: "Транзакция успешно выполнена!",
                    type: 'success'
                });
            } catch (error) {
                // Обработка ошибок
                console.error('Ошибка при отправке транзакции:', error);
                Vue.notify({
                    group: 'message',
                    title: 'Ошибка при отправке транзакции',
                    text: error.message,
                    type: 'error'
                });
            }
        },

        // Метод для отключения кошелька
        disconnectWallet() {
            this.tonConnectUI.disconnect();
            this.$store.dispatch('asyncIsConnectedWallet', false);
            this.$store.dispatch('asyncWalletData', null);
        },
    },


  components: {
      InputLabelTextDefault
  }
}
</script>
