<template>
    <div class="clicker-page ">
        <p>clicker-page</p>
    </div>
</template>

<script>

export default {
  name: 'clicker',
    data: function() {
      return {

      }
  },
  watch: {

  },

    created() {

    },
    destroyed() {

    },
    methods: {

    },
  computed: {
      languages() {
          return this.$store.state.language.list;
      },

  },
  mounted() {

  },
  components: {

  }
}
</script>
