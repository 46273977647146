export default {
    state: {
        user_info: null,
    },
    getters: {

    },
    actions: {
        asyncUserInfo(context, payload) {
            context.commit('updateUserInfo', payload);
        },

    },
    mutations: {
        updateUserInfo(state, data) {
            this.state.auth.user_info= data;
        },
    }
}