<template>
    <div class=" bonus-page">
        <p class="name-1">Бонусы</p>
        <p class="desc-1">Специальные предложения от нас и наших партнеров</p>
        <ul class="bonus-block">
            <li class="list" onclick="openWindowBonus()">
                <div class="img" style="background-image: url('/dist/images/present.svg')"></div>
                <div class="text">
                    <p class="name">Первый депозит</p>
                    <div class="bonus">
                        <p><img src="/dist/images/energy.svg" alt="">+ 500 Energy</p>
                        <p><img src="/dist/images/Icon-mini.svg" alt="">+ 1 Exclusive cards</p>
                    </div>
                </div>
            </li>
            <li class="list done">
                <div class="img" style="background-image: url('/dist/images/tg-icon.svg')"></div>
                <div class="text">
                    <p class="name">Подписка на наш телеграм канал</p>
                    <div class="bonus">
                        <p><img src="/dist/images/energy.svg" alt="">+ 1000 Energy</p>
                        <p><img src="/dist/images/Icon-mini.svg" alt="">+ 1 Exclusive cards</p>
                    </div>
                </div>
            </li>
            <li class="list subsc">
                <div class="img" style="background-image: url('/dist/images/tg-icon.svg')"></div>
                <div class="text">
                    <p class="name">Crypto Partner Chanel Name</p>
                    <div class="bonus">
                        <p><img src="/dist/images/energy.svg" alt="">+ 100 Energy</p>
                        <p><img src="/dist/images/Icon-mini.svg" alt="">+ 0.1 NOT</p>
                    </div>
                </div>
            </li>
        </ul>
<!--        <div class="btn-special">-->
<!--            <a >Создать спец. предложение</a>-->
<!--            <a class="special">Мои спец. предложения</a>-->
<!--        </div>-->
    </div>
</template>

<script>

export default {
  name: 'friends',
    data: function() {
      return {

      }
  },
    computed: {
        user_info() {
            return this.$store.state.auth.user_info
        },

    },
  watch: {

  },

    created() {

    },
    destroyed() {

    },
    methods: {

    },

    mounted() {

    },
  components: {

  }
}
</script>
