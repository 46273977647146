<template>
    <div v-if="user_info" class="home-menu">
        <div class="top-block">
            <div class="left">
                <img src="/dist/images/profile.svg" alt="">
                <div class="text">
                    <p>ID: {{user_info.user.telegram_id}}</p>
                    <span>{{ user_info.user.first_name }} {{ user_info.user.last_name }}</span>
                </div>
            </div>
            <div class="right">
                <router-link :to="{ name: 'deposit' }" class="btn-white">
                    <img src="/dist/images/icon-ton.svg" alt="">
                    Finance
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'HomeMenu',
        data: function() {
            return {

            }
        },
        methods:{

        },
        computed: {
            user_info() {
                return this.$store.state.auth.user_info
            },
        },
        created () {

        },
        destroyed () {

        },
        components: {


        }
    }

</script>

<style scoped>

</style>