<template>
    <div v-if="dialog">
        <v-row justify="center">
            <v-dialog
                    v-model="dialog" persistent :max-width="width"
                    v-on:click:outside="modalClose"
            >
                <div class="white " style="position: relative">

<!--          <span class="close" v-if="close" v-on:click="modalClose">-->
<!--            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--              <path d="M1.29785 17.0249L17.2979 1.0249" stroke="#170F49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              <path d="M17.2979 17.0249L1.29785 1.0249" stroke="#170F49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--            </svg>-->
<!--          </span>-->
                    <slot></slot>

                </div>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
    export default {

        name: 'ModalDefault',
        data: function() {
            return {
                dialog: true,
                submitDisabled: false
            }
        },
        props: {
            modal_cancel: {
                type: [Boolean, Object, Array, String, Number]
            },
            width: {
                type: String,
                default: '540px'
            },
            close: {
                type: Boolean,
                default: true
            }

        },
        computed: {

        },
        watch: {

        },
        methods: {
            modalClose: function () {
                this.$emit('modalCancel', this.modal_cancel);
                this.$emit('update:modal_cancel', false);
            }
        },
        components: {
        },
    };
</script>
