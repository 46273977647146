<template>
    <div class="block">
        <img class="img-block" src="/dist/images/profile.svg" alt="">
        <div>
            <p class="user-name">{{item.first_name}} {{item.last_name}}</p>
            <div class="c-k">
                <p class="profit">Your Profit:</p>
                <p class="click">
                    <img class="img" src="/dist/images/click-icon.svg" alt="">
                    + {{$primNum(item.bonus_ref, 2)}} Clicks
                </p>
                <p class="coin">
                    <img class="img" src="/dist/images/ton-symbol.svg" alt="">
                    +{{$primNum(item.bonus_day, 2)}} NOT
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ItemFriends',
        data: function() {
            return {

            }
        },

        props: {
            item: {
                type: Object
            }
        },

        watch: {

        },

        computed: {

        },
        methods:{

        },
        created() {

        },
        components: {
        }
    }


</script>

<style scoped>

</style>