export default {
    state: {
        auth: null,
    },
    getters: {
        auth(state) {
            return state.auth;
        }
    },
    actions: {
        asyncAuth({ commit }, payload) {
            commit('updateAuth', payload);
        }
    },
    mutations: {

    }
}