<template>
    <div class="item" @click="openModal">
        <div class="img" :style="{backgroundImage: 'url('+item.image+')'}"></div>
        <div class="item-top">
            <p class="name">{{item.name}}</p>
            <p class="lvl"><img src="/dist/images/star.svg" alt=""> LVL {{item.level}}</p>
        </div>
        <p class="text">{{item.text[0].text}}</p>
        <div class="item-stat">
            <p class="profit">+{{this.$primNum(item.profit_day, 2)}} NOT / Day</p>
            <p class="price"><img src="/dist/images/ton-symbol.svg" alt="">{{this.$primNum(item.price, 2)}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ItemCards',
        data: function() {
            return {

            }
        },

        props: {
            item: {
                type: Object
            },
            type: {
                type: Number
            },
            dialogText: {
                type: [Boolean, Object],
            }
        },

        watch: {

        },

        computed: {

        },
        methods:{
            openModal() {
                if (this.type == 1 || this.type == 2) {
                    this.$emit('update:dialogText', this.item)
                } else {
                    return false
                }
            }
        },
        created() {

        },
        components: {
        }
    }


</script>

<style scoped>

</style>