<template>
    <div v-if="user_info" class="home-page all-pages">
        <LevelProgress></LevelProgress>
        <ProfitMenu></ProfitMenu>

        <div class="click-block" @click="handleClick" ref="clickBlock">
            <div v-for="(point, index) in points" :key="index" class="plus-one" :style="{ top: point.y + 'px', left: point.x + 'px' }" ref="plusOne">
                +0.01
                <img src="/dist/images/icon-cc.svg" alt="">
            </div>
            <div class="img">
                <img class="cl-1" src="/dist/images/icon-click-1.png" alt="" id="circle">
<!--                <img class="cl-2" src="/dist/images/cl-2.png" alt="">-->
            </div>
            <div class="stat">
                <p class="energy"><img src="/dist/images/energy.svg" alt=""> {{user_info.energy_now}}/{{user_info.energy_max}}</p>
                <p class="c"><img src="/dist/images/click-icon.svg" alt=""> 0.01 NOT</p>
            </div>
        </div>
        <div class="block-text">
            <div class="block">
                <div class="img">
                    <img src="/dist/images/text-3.svg" alt="">
                </div>
                <div class="text">
                    <p class="name">Благодаря усилиям вашего пальца вы получили</p>
                    <p class="desc"><img src="/dist/images/Icon-mini.svg" alt=""> <span>{{$primNum(user_info.info.click_sum, 2)}} NOT</span> всего</p>
                </div>
            </div>
            <div class="block">
                <div class="img">
                    <img src="/dist/images/text-1.svg" alt="">
                </div>
                <div class="text">
                    <p class="name">Карты которыми вы владеете, генерируют вам</p>
                    <p class="desc"><img src="/dist/images/Icon-mini.svg" alt=""> <span>{{$primNum(user_info.info.cart_sum, 2)}} NOT</span> ежедневно</p>
                </div>
            </div>
            <div class="block">
                <div class="img">
                    <img src="/dist/images/text-2.svg" alt="">
                </div>
                <div class="text">
                    <p class="name">Друзья которых вы пригласили, генерируют вам</p>
                    <p class="desc"><img src="/dist/images/Icon-mini.svg" alt=""> <span>{{$primNum(user_info.info.friend_sum, 2)}} NOT</span> ежедневно</p>
                </div>
            </div>
        </div>

        <ModalDefault v-if="dialogVideo">
            <div class="window-buy">
                <div class="window-block">
                    <p class="name">Title</p>
                    <div class="img"></div>
                    <p class="desc">Textt xtx </p>
                    <div class="block">
                        <div class="profit">
                            <p>Прибыль в час:</p>
                            <span>+15 NOT / Day</span>
                        </div>
                        <div class="summ profit">
                            <p>Стоимость</p>
                            <span>
                        <img src="/dist/images/ton-symbol.svg" alt="">
                        0.10
                    </span>
                        </div>
                    </div>
                    <div class="btn-block">
                        <a class="btn-blue" >Получить</a>
                    </div>
                </div>
            </div>
        </ModalDefault>
    </div>
</template>

<script>

import LevelProgress from "../modules/LevelProgress";
import ProfitMenu from "../modules/ProfitMenu";
import ModalDefault from "../modules/ModalDefault";
export default {
    name: 'home',
    data: function() {
      return {
          dialogVideo: false,
          points: [],
          countPoints: 0,
          farmTimer: null
      }
    },
    watch: {

    },

    computed: {
        user_info() {
            return this.$store.state.auth.user_info
        },
    },

    created() {

    },
    destroyed() {

    },
    methods: {
        handleClick(event) {
            if (Number(this.user_info.energy_now) > 0) {
                // Сбрасываем таймер, если он есть
                if (this.farmTimer) {
                    clearTimeout(this.farmTimer);
                }
                this.countPoints += 1;

                // Рассчитываем координаты клика относительно блока
                const blockRect = this.$refs.clickBlock.getBoundingClientRect();
                const x = event.clientX - blockRect.left;
                const y = event.clientY - blockRect.top;

                // Добавляем новый элемент для анимации
                const newPoint = { x, y };
                this.points.push(newPoint);

                this.$nextTick(() => {
                    const plusOneElement = this.$refs.plusOne[this.points.length - 1];
                    if (plusOneElement) {
                        plusOneElement.addEventListener('animationend', () => {
                            this.points.shift();  // Удаляем первый элемент массива
                        });
                    }
                });

                // Логика для изменения анимации изображения в зависимости от позиции клика
                const imgElement = this.$el.querySelector('.cl-1');
                const rect = imgElement.getBoundingClientRect();
                let clickX = event.clientX - rect.left;
                let clickY = event.clientY - rect.top;

                // Условие для изменения направления анимации
                if (clickX < rect.width / 2 && clickY < rect.height / 2) {
                    imgElement.style.transform = 'scale(0.95) rotate(-2deg)';
                } else if (clickX >= rect.width / 2 && clickY < rect.height / 2) {
                    imgElement.style.transform = 'scale(0.95) rotate(2deg)';
                } else if (clickX < rect.width / 2 && clickY >= rect.height / 2) {
                    imgElement.style.transform = 'scale(0.95) rotate(2deg)';
                } else if (clickX >= rect.width / 2 && clickY >= rect.height / 2) {
                    imgElement.style.transform = 'scale(0.95) rotate(-2deg)';
                }

                setTimeout(() => {
                    imgElement.style.transform = 'scale(1) rotate(0)';
                }, 200);

                // Логика обновления информации о пользователе и отправки данных
                let new_user_info = this.$cleanObject(this.user_info);
                new_user_info.level_now += 1;
                new_user_info.energy_now -= 1;
                new_user_info.available += 0.01;
                this.$store.dispatch('asyncUserInfo', new_user_info);

                if (Number(this.user_info.level_now) === Number(this.user_info.level_next) || Number(this.user_info.energy_now) === 0) {
                    let request = { count: this.countPoints };
                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/click', request)
                        .then(response => response.json())
                        .then((response) => {
                            let user_info = response.data;
                            this.$store.dispatch('asyncUserInfo', user_info);
                            this.countPoints = 0;
                        }).catch(err => this.httpHandler(err));
                }

                this.farmTimer = setTimeout(() => {
                    let request = { count: this.countPoints };
                    this.$http[(process.env.NODE_ENV === 'production') ? 'post' : 'get']('ajax/click', request)
                        .then(response => response.json())
                        .then((response) => {
                            let user_info = response.data;
                            this.$store.dispatch('asyncUserInfo', user_info);
                            this.countPoints = 0;
                        }).catch(err => this.httpHandler(err));
                }, 1000);
            } else {
                return false;
            }
        }
    },

    mounted() {

    },
    components: {
      ModalDefault,
      ProfitMenu,
      LevelProgress

    }
}
</script>


<style>
    .cl-1 {
        transition: transform 0.1s ease;
    }
    .plus-one img{
        margin-left: 4px;
    }
    .plus-one {
        z-index: 1000;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        /*z-index: +1;*/
        font-size: 18px;
        text-shadow: -1px 0 #ffffff, 0 1px #ffffff, 1px 0 #ffffff, 0 -1px #ffffff;
        color: #000000;
        animation: floatUp 2s forwards;
    }

    @keyframes floatUp {
        0% {
            opacity: 1;
            transform: translateY(0);
        }
        100% {
            opacity: 0;
            transform: translateY(-100px);
        }
    }
</style>