<template>
    <div>
        <div v-if="user_info" class="profit-menu">
            <div class="left">
                <div class="img"><img src="/dist/images/icon-a.svg" alt=""></div>
                <div class="text">
                    <p class="a">Available <span>$ {{$primNum(Number(user_info.available) * Number(user_info.course), 2)}} </span></p>
                    <p class="p">{{$primNum(user_info.available, 2)}}</p>
                </div>
            </div>
            <div class="right">
                <div class="text">
                    <p class="t">Generate per Day</p>
                    <p class="a"><span><img src="/dist/images/icon-a.svg" alt=""></span>{{$primNum(user_info.generate_day, 2)}}</p>
                </div>
                <div class="img" @click="dialogText = true"><img src="/dist/images/info-circle.svg" alt=""></div>
            </div>
        </div>
        <ModalDefault v-if="dialogText" v-bind:modal_cancel.sync="dialogText">
            <div class="window-info">
                <div class="window-block">
                    <p class="name">Как это работает?</p>
                    <p class="desc">Покупайте карточки что бы увеличить доход. Доход начисляется посекундно в режиме реального времени</p>
                    <div class="block">
                        <div class="day">
                            <p class="t">Generate per Day</p>
                            <p class="img">
                                <span>{{$primNum(user_info.generate_day, 2)}}</span>
                            </p>
                        </div>
                        <p class="text">Здесь подсчитана мощность всех купленных карточек +10% мощности ваших рефералов</p>
                    </div>
                    <div class="block1">
                        <p class="text">Здесь отображается доступный баланс, который можно использовать для покупок новых карточек или вывода на ваш кошелек NOT</p>
                        <div class="coin">
                            <img class="img" src="/dist/images/Icon.svg" alt="">
                            <div>
                                <p class="t">Available</p>
                                <p class="c">{{$primNum(user_info.available, 2)}}</p>
                            </div>
                        </div>

                    </div>
                    <p class="rating">Рейтинг</p>
                    <div class="level">
                        <div class="progress-lvl">
                            <v-progress-linear :value="(Number(user_info.level_now) * 100) / Number(user_info.level_next)" :height="4"></v-progress-linear>
                        </div>
                        <div class="progress-text">
                            <p class="text">
                                <img src="/dist/images/star.svg" alt="">
                                <span>Level {{user_info.level}}</span>
                            </p>
                            <p class="progress">{{user_info.level_now}}/{{user_info.level_next}}</p>
                        </div>
                    </div>
                    <p class="tt">Чем выше рейтинг - тем больше доступно энергии. Энергия используется для печати TON в ручном режиме при нажатии на монету </p>
                    <div class="btn-block">
                        <a class="btn-blue" @click="dialogText = false">Ok</a>
                    </div>
                </div>
            </div>
        </ModalDefault>
    </div>

</template>

<script>
    import ModalDefault from "../modules/ModalDefault";
    export default {
        name: 'ProfitMenu',
        data: function() {
            return {
                dialogText: false
            }
        },

        computed: {
            user_info() {
                return this.$store.state.auth.user_info
            },
        },
        props: {

        },
        methods: {


        },
        created() {

        },
        components: {
            ModalDefault
        }
    }
</script>
<style scoped>


</style>
