<template>
  <v-app id="app" >
      <router-view></router-view>
      <notifications group="message" class="notise" position="bottom right" />
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: function() {
      return {

      }
  },
  watch: {

  },
  methods:{

  },
  computed: {

  },
  created () {
      this.$http.get('ajax/user_info')
      .then(response => {
          return response.json()
      })
      .then(response => {
          let user_info = response.data;
          this.$store.dispatch('asyncUserInfo', user_info);
      }).catch((err) => {
          this.httpHandler(err);
      });
  },
    mounted() {


    },
    destroyed () {


    },
  components: {

  },
}
</script>

<style>

</style>

