<template>
    <div class="deposit-page">
        <div class="fin-block">
            <div class="dropdown">
                <button @click="toggleDropdown">
                    Отправить NOT на кошелек
                    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.07745 0.910723C1.40289 0.585286 1.93053 0.585286 2.25596 0.910723L6.66671 5.32147L11.0775 0.910723C11.4029 0.585286 11.9305 0.585286 12.256 0.910723C12.5814 1.23616 12.5814 1.7638 12.256 2.08923L7.25596 7.08924C6.93053 7.41467 6.40289 7.41467 6.07745 7.08924L1.07745 2.08923C0.752015 1.7638 0.752015 1.23616 1.07745 0.910723Z" fill="#FCFCFF"/>
                    </svg>
                </button>
                <div v-if="isDropdownOpen" class="dropdown-content">
                    <router-link :to="{ name: 'deposit' }" @click="selectItem('Опция 1')">Добавить NOT</router-link>
                    <router-link :to="{ name: 'withdrawal' }" @click="selectItem('Опция 2')">Отправить NOT на кошелек</router-link>
                </div>
            </div>
            <div class="block-input">
                <img src="/dist/images/icon-ton.svg" alt="">
                <input type="text" placeholder="1000">
                <p class="ton">NOT</p>
            </div>
            <div class="btn-block">
                <a class="btn-blue">Забрать NOT с доступных</a>
            </div>
            <div class="text">
                <img src="/dist/images/with-1.svg" alt="">
                <p>Мы оплатим комиссию вместо вас, вы получите ровную сумму на свой кошелек</p>
            </div>
            <div class="text">
                <img src="/dist/images/dep-1.svg" alt="">
                <p>Минимальный вывод <span>1000 NOT</span>, это помогает снять нагрузку с ситемы</p>
            </div>
            <div class="text">
                <img src="/dist/images/dep-3.svg" alt="">
                <p>Средства поступят на ваш кошелек автоматически в течении 24 часов</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'withdrawal',
    data: function() {
      return {
          isDropdownOpen: false,
          selectedItem: null,
      }
  },
  watch: {

  },

    created() {

    },
    destroyed() {

    },
    methods: {
        toggleDropdown() {
            this.isDropdownOpen = !this.isDropdownOpen;
        },
        selectItem(item) {
            this.selectedItem = item;
            this.isDropdownOpen = false;
        },
    },
  computed: {
      languages() {
          return this.$store.state.language.list;
      },

  },
  mounted() {

  },
  components: {

  }
}
</script>
